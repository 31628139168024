export var setParams = function (data) {
    var page = data.page, limit = data.limit, search = data.search, orderBy = data.orderBy, order = data.order;
    var params = { page: page, limit: limit };
    if (search && typeof search === 'string')
        params.search = search;
    if (orderBy && order) {
        params.sort = order === 'desc' ? "-".concat(orderBy) : "".concat(orderBy);
    }
    return params;
};
export var setQuery = function (params) {
    return "?page=".concat(params.page, "&limit=").concat(params.limit).concat(params.search ? "&search=".concat(params.search) : '');
};
